var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-1",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-0 px-6",attrs:{"cols":"12"}},[_c('v-card',{class:[
          {
            disabled:
              _vm.$store.getters['auth/isTattooer'] &&
              !_vm.$store.getters['auth/isTattooerStudio'] &&
              false,
          } ],style:(_vm.$vuetify.breakpoint.lgAndDown
            ? 'height: auto'
            : 'padding: 0px 50px 0px 50px;'),attrs:{"elevation":"6"}},[(_vm.accounts.stripe && _vm.accounts.stripe.requirements.errors.length)?_c('div',{staticClass:"login_error text-center"},[_vm._v(" "+_vm._s(_vm.$t("payments_sistems.stripe.error"))+" ")]):_vm._e(),_c('v-card-title',[_c('v-col',{staticClass:"pb-0 pr-0",attrs:{"cols":"2"}},[_c('img',{staticClass:"no_grey",staticStyle:{"height":"60px"},attrs:{"src":require('@/assets/logo.svg')}})]),_c('v-col',{class:_vm.$vuetify.breakpoint.xsOnly
                ? ' pl-8 pt-3 pr-0'
                : 'pl-0 pt-3 pr-0',staticStyle:{"display":"flex","justify-content":"start","margin-left":"-10px"},attrs:{"cols":"10","sm":"4","md":"4","xl":"4"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$tc("payments_sistems.stripe.powered"))+" ")]),_c('img',{staticClass:"ml-1",staticStyle:{"height":"30px"},attrs:{"src":require('@/assets/stripe_logo.png')}})]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","xl":"6"}},[_c('v-row',{attrs:{"justify":"end"}},[(!_vm.paymentSistems.find(function (x) { return x.key == 'pm_stripe_id'; }))?_c('v-btn',{staticClass:"pl-5 btnVincular",staticStyle:{"height":"5px !important"},attrs:{"elevation":"0","outlined":"","disabled":_vm.$store.getters['auth/isTattooer'] &&
                  !_vm.$store.getters['auth/isTattooerStudio'] &&
                  false},on:{"click":_vm.conectStripe}},[_vm._v(_vm._s(_vm.$t("accounts.connect")))]):[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","xl":"6"}},[_c('v-btn',{staticClass:"btnVincular",staticStyle:{"height":"5px !important","width":"100% !important"},attrs:{"color":"primary","disabled":_vm.$store.getters['auth/isTattooer'] &&
                        !_vm.$store.getters['auth/isTattooerStudio'] &&
                        false,"elevation":"0"},on:{"click":function($event){return _vm.disconectAccount('stripe')}}},[_vm._v(" "+_vm._s(_vm.$t("accounts.disconnect"))+" ")])],1),_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown ? '' : 'px-0',attrs:{"cols":"12","sm":"6","md":"6","xl":"6"}},[_c('v-btn',{staticClass:"btnVincular",staticStyle:{"height":"5px !important","width":"100% !important"},attrs:{"color":"primary","disabled":_vm.$store.getters['auth/isTattooer'] &&
                        !_vm.$store.getters['auth/isTattooerStudio'] &&
                        false,"elevation":"0"},on:{"click":_vm.goToStripeDashboard}},[_vm._v(" "+_vm._s(_vm.$t("accounts.see"))+" ")])],1)],1)]],2)],1)],1),_c('v-card-text',{staticClass:"pt-0",staticStyle:{"font-size":"12px","color":"white"}},[_c('v-col',{staticClass:"pb-5"},[_vm._v(" "+_vm._s(_vm.$t("payments_sistems.stripe.part_1"))+" ")]),_c('v-divider'),_c('v-col',{staticClass:"py-5"},[_vm._v(" "+_vm._s(_vm.$t("payments_sistems.stripe.part_2"))+" "),_c('a',{staticStyle:{"text-decoration":"none !important"},attrs:{"href":"https://stripe.com/en-gb-es/pricing","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("payments_sistems.stripe.part_2_link")))])]),_c('v-divider'),_c('v-col',{staticClass:"pt-5 pl-1"},[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t("payments_sistems.stripe.part_3_1")))])])])],1)],1),_c('v-card-actions',{style:(_vm.$vuetify.breakpoint.lgAndDown ? '' : 'margin-bottom: 255px')})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }