<template>
  <v-container class="px-1" fluid>
    <v-row justify="center">
      <v-col cols="12" class="py-0 px-6">
        <v-card
          elevation="6"
          :style="
            $vuetify.breakpoint.lgAndDown
              ? 'height: auto'
              : 'padding: 0px 50px 0px 50px;'
          "
          v-bind:class="[
            {
              disabled:
                $store.getters['auth/isTattooer'] &&
                !$store.getters['auth/isTattooerStudio'] &&
                false,
            },
          ]"
        >
          <div
            v-if="accounts.stripe && accounts.stripe.requirements.errors.length"
            class="login_error text-center"
          >
            {{ $t("payments_sistems.stripe.error") }}
          </div>

          <v-card-title
            ><v-col cols="2" class="pb-0 pr-0">
              <img
                class="no_grey"
                :src="require('@/assets/logo.svg')"
                style="height: 60px"
              /> </v-col
            ><v-col
              cols="10"
              sm="4"
              md="4"
              xl="4"
              :class="
                $vuetify.breakpoint.xsOnly
                  ? ' pl-8 pt-3 pr-0'
                  : 'pl-0 pt-3 pr-0'
              "
              style="display: flex; justify-content: start; margin-left: -10px"
            >
              <span>
                {{ $tc("payments_sistems.stripe.powered") }}
              </span>
              <img
                class="ml-1"
                :src="require('@/assets/stripe_logo.png')"
                style="height: 30px"
            /></v-col>
            <v-col cols="12" sm="6" md="6" xl="6">
              <v-row justify="end">
                <v-btn
                  elevation="0"
                  class="pl-5 btnVincular"
                  style="height: 5px !important"
                  outlined
                  :disabled="
                    $store.getters['auth/isTattooer'] &&
                    !$store.getters['auth/isTattooerStudio'] &&
                    false
                  "
                  v-if="!paymentSistems.find((x) => x.key == 'pm_stripe_id')"
                  @click="conectStripe"
                  >{{ $t("accounts.connect") }}</v-btn
                >
                <template v-else>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" xl="6">
                      <v-btn
                        @click="disconectAccount('stripe')"
                        color="primary"
                        :disabled="
                          $store.getters['auth/isTattooer'] &&
                          !$store.getters['auth/isTattooerStudio'] &&
                          false
                        "
                        elevation="0"
                        style="height: 5px !important; width: 100% !important"
                        class="btnVincular"
                      >
                        {{ $t("accounts.disconnect") }}
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      xl="6"
                      :class="$vuetify.breakpoint.smAndDown ? '' : 'px-0'"
                    >
                      <v-btn
                        color="primary"
                        :disabled="
                          $store.getters['auth/isTattooer'] &&
                          !$store.getters['auth/isTattooerStudio'] &&
                          false
                        "
                        elevation="0"
                        style="height: 5px !important; width: 100% !important"
                        class="btnVincular"
                        @click="goToStripeDashboard"
                      >
                        {{ $t("accounts.see") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-row></v-col
            ></v-card-title
          >
          <v-card-text class="pt-0" style="font-size: 12px; color: white">
            <v-col class="pb-5">
              {{ $t("payments_sistems.stripe.part_1") }}
            </v-col>
            <v-divider></v-divider>
            <v-col class="py-5">
              {{ $t("payments_sistems.stripe.part_2") }}
              <a
                style="text-decoration: none !important"
                href="https://stripe.com/en-gb-es/pricing"
                target="_blank"
                >{{ $t("payments_sistems.stripe.part_2_link") }}</a
              >
            </v-col>
            <v-divider></v-divider>
            <v-col class="pt-5 pl-1">
              <ul>
                <li>{{ $t("payments_sistems.stripe.part_3_1") }}</li>
                <!--<li>{{ $t("payments_sistems.stripe.part_3_2") }}</li>-->
              </ul>
            </v-col>
          </v-card-text>
        </v-card>
        <v-card-actions
          :style="$vuetify.breakpoint.lgAndDown ? '' : 'margin-bottom: 255px'"
        >
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "PaymentSistem",
  mounted() {},
  components: {
    ConectionCard: () => import("@/components/user/settings/connection/Card"),
  },
  data() {
    return {
      dialog: false,
      stripeWindow: null,
      paymentSistems: [],
      accounts: {
        stripe: null,
      },
    };
  },

  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions("payments", [
      "getPaymentSistems",
      "getAccount",
      "getLogIn",
      "preConnect",
      "connect",
      "disconnect",
    ]),
    fetch() {
      this.getPaymentSistems().then((data) => {
        this.paymentSistems = data;
        this.paymentSistems.forEach((ps) => {
          switch (ps.key) {
            case "pm_stripe_id":
              this.fetchAccount("stripe");
              break;
          }
        });
      });
    },
    fetchAccount(type) {
      this.getAccount({ type }).then((data) => {
        this.accounts[type] = data;
      });
    },
    goToStripeDashboard() {
      this.getLogIn({ type: "stripe" }).then((data) => {
        this.openPopup(data.url);
      });
    },
    conectStripe() {
      this.preConnect({ type: "stripe" }).then((data) => {
        //console.log(data);
        this.openPopup(data.url);

        //window.location.href = data.url;
      });
    },
    disconectAccount(type) {
      this.$confirm(
        "¿Seguro que quieres decsonectar " + type + "?",
        "",
        ""
      ).then(() => {
        this.disconnect({ type }).then(() => {
          this.fetch();
        });
      });
    },
    openPopup(url) {
      /*
      let w = null;
      if (
        this.$browserDetect.isSafari ||
        this.$browserDetect.meta.name === "Safari"
      ) {
        w = window.location.assign(
          url,
          "popup",
          "width=600,height=600,scrollbars=no,resizable=no"
        );
      } else {
       
      }
      */
      let w = window.open(
        url,
        "_blank",
        "width=600,height=600,scrollbars=no,resizable=no"
      );
      var loop = setInterval(() => {
        //console.log("INTERVAL", w.closed);
        if (w.closed) {
          //console.log("clearInterval");
          clearInterval(loop);

          this.fetch();
          this.$store.dispatch("auth/getSettings");
        }
      }, 1000);
    },
  },
};
</script>

<style lang="sass">
.btnVincular
  width: 120px !important
  font-size: 14px !important
  height: 35px !important
#changePassword
  label
    display: block
    height: 25px
</style>
<style scoped lang="sass">
.disabled
  *
    color: var(--v-gris1-base) !important
  img:not(.no_grey)
    filter: grayscale(100%)
</style>